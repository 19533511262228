import Link from "next/link";
import styles from "./card-mini.module.scss";
import Image from "next/image";
import { Stars } from "../stars/stars";
import { FC } from "react";
import classNames from "classnames";
import NoPhotoImg from "../../assets/images/no-photo.webp";
import { ICardMini } from "@/services/types/types";
import { CardMiniActions } from "./card-mini-actions/card-mini-actions";
import { CardMiniLabels } from "./card-mini-labels/card-mini-labels";
import { CardMiniPrice } from "./card-mini-price/card-mini-price";
import { CardMiniButtons } from "./card-mini-buttons/card-mini-buttons";
import { decodeString } from "@/utils/api/decodeString";
import { usePathname } from "next/navigation";
interface ICardMiniProps {
  card: ICardMini;
  className?: string;
  onClick?: () => void;
  disable?: boolean;
  onClickFavourite?: () => void;
  onClickAddToCart?: () => void;
}
export const CardMini: FC<ICardMiniProps> = ({
  card,
  className,
  onClick,
  disable = false,
  onClickFavourite,
  onClickAddToCart
}) => {
  const pathname = usePathname();
  const imgWrapClassnames = classNames({
    [styles.imgWrap]: true,
    [styles.notAvailable]: card.comission ? false : !card.available
  });
  const wrapClassnames = classNames({
    [styles.wrap]: true,
    [className!]: className
  });
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (pathname === "/search/") {
      ym(42989679, "reachGoal", "goingToCardFromBigSearch");
    }
  };
  return <div className={wrapClassnames} aria-disabled={disable} data-sentry-component="CardMini" data-sentry-source-file="card-mini.tsx">
      <CardMiniActions card={card} onClickFavourite={onClickFavourite} data-sentry-element="CardMiniActions" data-sentry-source-file="card-mini.tsx" />
      <meta itemProp="itemCondition" content="https://schema.org/NewCondition" data-sentry-element="meta" data-sentry-source-file="card-mini.tsx" />
      <Link prefetch={false} href={`/card/${card.id}/`} className={styles.link} onClick={handleClick} title={card.name} data-sentry-element="Link" data-sentry-source-file="card-mini.tsx">
        <meta itemProp="image" content={`https://ohotaktiv.ru${card.preview_picture}`} data-sentry-element="meta" data-sentry-source-file="card-mini.tsx" />
        <CardMiniLabels card={card} data-sentry-element="CardMiniLabels" data-sentry-source-file="card-mini.tsx" />
        <div className={imgWrapClassnames}>
          <Image className={card.comission ? styles.comissionImg : styles.img} alt={card.name} src={card.preview_picture ? `https://ohotaktiv.ru${card.preview_picture}` : NoPhotoImg} width={500} height={500} itemProp="image" data-sentry-element="Image" data-sentry-source-file="card-mini.tsx" />
        </div>
        {!card.comission && <Stars className={styles.reviews} stars={card.reviews} />}
        <p className={styles.name}>
          <meta itemProp="name" content={card.name} data-sentry-element="meta" data-sentry-source-file="card-mini.tsx" />
          {decodeString(card.name)}
        </p>
      </Link>
      <div className={`${styles.bottom} ${card.comission && styles.comission}`}>
        <CardMiniPrice card={card} data-sentry-element="CardMiniPrice" data-sentry-source-file="card-mini.tsx" />
        <CardMiniButtons card={card} onClickAddToCart={onClickAddToCart} data-sentry-element="CardMiniButtons" data-sentry-source-file="card-mini.tsx" />
      </div>
    </div>;
};