import { Label } from "@/components/label/label";
import styles from "./card-mini-labels.module.scss";
import { FC } from "react";
import { ICardMini } from "@/services/types/types";
interface ICardMiniLabels {
  card: ICardMini;
}
export const CardMiniLabels: FC<ICardMiniLabels> = ({
  card
}) => {
  const isNew = card.labels && card.labels.new;
  const isFreeDelivery = card.labels && card.labels.delivery;
  const countDiscount = card.labels && card.labels.discount && card.discount_percent >= 10 ? `-${card.discount_percent}%` : false;
  const isLicense = card.product_license && card.product_license === true;
  const firstWeapon = card.labels?.first_weapon;
  return (card.labels || isLicense) && <div className={styles.labelWrap}>
        {firstWeapon && <Label text="Первое оружие" label="first" />}
        {isLicense && <Label text="Лицензионный товар" label="license" />}
        {isNew && <Label text="Новинка" label="new" />}
        {/* {isFreeDelivery && <Label text="FREE" label="delivery" />} */}
        {countDiscount && <Label text={countDiscount} label="sale" />}
      </div>;
};