"use client";

import { getCookie, setCookie } from "@/utils/functions/cookie";
import { persistor, store } from "../redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { keyGenerator } from "@/utils/functions/keyGenerator";
export function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  if (typeof window !== "undefined" && !getCookie("deviceId")) setCookie("deviceId", keyGenerator(), {
    expires: 51840000
  });
  return <Provider store={store} data-sentry-element="Provider" data-sentry-component="Providers" data-sentry-source-file="provider.tsx">
      <PersistGate loading={null} persistor={persistor} data-sentry-element="PersistGate" data-sentry-source-file="provider.tsx">
        {() => children}
      </PersistGate>
    </Provider>;
}