import { ICardMini } from "@/services/types/types";
import styles from "./card-mini-price.module.scss";
import { FC } from "react";
import { priceWithSpaces } from "@/utils/functions/price-with-spaces";
interface ICardMiniPrice {
  card: ICardMini;
}
export const CardMiniPrice: FC<ICardMiniPrice> = ({
  card
}) => {
  return <div className={styles.priceWrap} itemProp="offers" itemScope itemType="https://schema.org/Offer" data-sentry-component="CardMiniPrice" data-sentry-source-file="card-mini-price.tsx">
      {card.price_discount !== card.price ? <>
          <meta itemProp="priceCurrency" content="RUB" />
          <meta itemProp="price" content={String(card.price_discount || card.price)} />
          <meta itemProp="availability" content={!!card.available !== true ? "https://schema.org/OutOfStock" : "https://schema.org/InStock"} />
          {card.comission !== true && <p className={styles.priceOld}>{priceWithSpaces(card.price)}</p>}
          {card.comission && <p className={styles.price}>{priceWithSpaces(card.price)}</p>}
          {card.price_discount && <p className={styles.price}>
              {priceWithSpaces(card.price_discount)}
            </p>}
        </> : <p className={styles.price}>{priceWithSpaces(card.price_discount)}</p>}
    </div>;
};