import Link from "next/link";
import styles from "./swiper-card-list.module.scss";
import { FC } from "react";
import { SwiperCollection } from "@/components/swiper-collection/swiper-collection";
import classNames from "classnames";
import { ICardMini } from "@/services/types/types";
interface ISwiperCardList {
  isVisibleTitle: boolean;
  hasLink: boolean;
  title: string;
  link?: string;
  items: Array<ICardMini>;
  onClick?: () => void;
}
export const SwiperCardList: FC<ISwiperCardList> = ({
  isVisibleTitle,
  hasLink,
  items,
  title,
  link = "",
  onClick
}) => {
  const titleClassNames = classNames({
    [styles.title]: true,
    "visually-hidden": !isVisibleTitle
  });
  return <section className={styles.section} data-sentry-component="SwiperCardList" data-sentry-source-file="swiper-card-list.tsx">
      <h2 className={titleClassNames}>
        {hasLink ? <>
            <Link prefetch={false} href={link}>
              {title}
            </Link>
            <Link prefetch={false} className={styles.titleLink} href={link}>
              смотреть
            </Link>
          </> : title}
      </h2>
      <SwiperCollection items={items} onClick={onClick} data-sentry-element="SwiperCollection" data-sentry-source-file="swiper-card-list.tsx" />
    </section>;
};