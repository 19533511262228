import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Metrika"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/analytics/metrika.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ArrowTop"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/arrow-top/arrow-top.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotFoundComponent"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/not-found-component/not-found-component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/notification/notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/services/providers/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/components/CookiePopup/index.tsx");
