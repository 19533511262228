"use client";

import Link from "next/link";
import styles from "./not-found-component.module.scss";
import { FC, useEffect } from "react";
import { PersonalRecommendedComponent } from "../personal-recommended-component/personal-recommended-component";
interface INotFoundComponent {}
export const NotFoundComponent: FC<INotFoundComponent> = () => {
  useEffect(() => {
    setTimeout(() => ym(42989679, "reachGoal", "not-found"), 1000);
  }, []);
  return <section data-sentry-component="NotFoundComponent" data-sentry-source-file="not-found-component.tsx">
      <div className="container">
        <div className={styles.content}>
          <div className={styles.textContent}>
            <h2 className={styles.subtitle}>
              <span>Упс...</span>
              <br />
              Мы не можем найти то, <span>что Вы ищете</span>
            </h2>
            <p className={styles.text}>
              Страницы не существует или она была удалена
            </p>
            <p className={styles.text}>
              Вернитесь{" "}
              <Link prefetch={false} href={"/"} className={styles.link} data-sentry-element="Link" data-sentry-source-file="not-found-component.tsx">
                на главную
              </Link>{" "}
              или загляните{" "}
              <Link prefetch={false} href={"/catalog"} className={styles.link} data-sentry-element="Link" data-sentry-source-file="not-found-component.tsx">
                в каталог
              </Link>
            </p>
          </div>
          <div className={styles.imgWrap}>
            <h1 className={styles.title}>404</h1>
          </div>
        </div>
        <PersonalRecommendedComponent data-sentry-element="PersonalRecommendedComponent" data-sentry-source-file="not-found-component.tsx" />
      </div>
    </section>;
};